import baseInfoForm from './BaseInfoForm'
import areaInfoForm from './AreaInfoForm'
import ownerInfoForm from './OwnerInfoForm'
import truckSpaceInfoForm from './TruckSpaceInfoForm'
import equipmentInfoForm from './EquipmentInfoForm'
import financeInfoForm from './FinanceInfoForm'
import annexInfoForm from './AnnexInfoForm'
export {
  baseInfoForm,
  areaInfoForm,
  ownerInfoForm,
  truckSpaceInfoForm,
  equipmentInfoForm,
  financeInfoForm,
  annexInfoForm
}
