<template>
  <div class="baseInfoForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <el-form-item label="规划总固定车位数" :rules="[{ required: true, message: '请输入规划总固定车位数', trigger: 'blur' }]" prop="planFixedParkNum">
        <v-input-number v-model="form.planFixedParkNum" placeholder="请输入规划总固定车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划地上总固定车位数" :rules="[{ required: true, message: '请输入规划地上总固定车位数', trigger: 'blur' }]" prop="planUpperFixedParkNum">
        <v-input-number v-model="form.planUpperFixedParkNum" placeholder="请输入规划地上总固定车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划地下总固定车位数" :rules="[{ required: true, message: '请输入规划地下总固定车位数', trigger: 'blur' }]" prop="planDownFixedParkNum">
        <v-input-number v-model="form.planDownFixedParkNum" placeholder="请输入规划地下总固定车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="其中：子母车位数" :rules="[{ required: true, message: '请输入子母车位数', trigger: 'blur' }]" prop="sonAndMotherParkNum">
        <v-input-number v-model="form.sonAndMotherParkNum" placeholder="请输入子母车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="固定车位单价列举" :rules="[{ required: true, message: '请列举固定车位单价，**元/月，多个价格以“|”符号分隔', trigger: 'blur' }]" prop="fixedParkPrice">
        <v-input v-model="form.fixedParkPrice" placeholder="请列举固定车位单价，**元/月，多个价格以“|”符号分隔" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="规划总临停车位数" :rules="[{ required: true, message: '请输入规划总临停车位数', trigger: 'blur' }]" prop="planTemporaryParkNum">
        <v-input-number v-model="form.planTemporaryParkNum" placeholder="请输入规划总临停车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划临停车位数单价列举" :rules="[{ required: true, message: '请列举规划临停车位数单价，**元/月，多个价格以“|”符号分隔', trigger: 'blur' }]" prop="temporaryParkPrice">
        <v-input v-model="form.temporaryParkPrice" placeholder="请列举规划临停车位数单价，**元/月，多个价格以“|”符号分隔" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="规划总车位数" :rules="[{ required: true, message: '总固定+总临停（剔除非标准车位）', trigger: 'blur' }]" prop="planParkNum">
        <v-input-number v-model="form.planParkNum" placeholder="总固定+总临停（剔除非标准车位）" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="车位比" :rules="[{ required: true, message: '请输入车位比', trigger: 'blur' }]" prop="parkRate">
        <v-input v-model="form.parkRate" placeholder="请输入车位比" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="规划产权车位数" :rules="[{ required: true, message: '请输入规划产权车位数', trigger: 'blur' }]" prop="planPropertyParkNum">
        <v-input-number v-model="form.planPropertyParkNum" placeholder="请输入规划产权车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划非产权车位数" :rules="[{ required: true, message: '请输入规划非产权车位数', trigger: 'blur' }]" prop="planNotPropertyParkNum">
        <v-input-number v-model="form.planNotPropertyParkNum" placeholder="请输入规划非产权车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划机械车位数" :rules="[{ required: true, message: '请输入规划机械车位数', trigger: 'blur' }]" prop="planMechanicsParkNum">
        <v-input-number v-model="form.planMechanicsParkNum" placeholder="请输入规划机械车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划人防车位数" :rules="[{ required: true, message: '请输入规划人防车位数', trigger: 'blur' }]" prop="planCivilAirDefenseParkNum">
        <v-input-number v-model="form.planCivilAirDefenseParkNum" placeholder="请输入规划人防车位数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划地上机动车位总数" :rules="[{ required: true, message: '请输入规划地上机动车位总数', trigger: 'blur' }]" prop="planUpperVehicleParkNum">
        <v-input-number v-model="form.planUpperVehicleParkNum" placeholder="请输入规划地上机动车位总数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划地下机动车位总数" :rules="[{ required: true, message: '请输入规划地下机动车位总数', trigger: 'blur' }]" prop="planDownVehicleParkNum">
        <v-input-number v-model="form.planDownVehicleParkNum" placeholder="请输入规划地下机动车位总数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="车位单价列举" :rules="[{ required: true, message: '请列举车位单价，**元/月，多个价格以“|”符号分隔', trigger: 'blur' }]" prop="parkPrice">
        <v-input v-model="form.parkPrice" placeholder="请列举车位单价，**元/月，多个价格以“|”符号分隔" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="规划总车库数-带产权" :rules="[{ required: true, message: '请输入规划总车库数-带产权', trigger: 'blur' }]" prop="planGarageNum">
        <v-input-number v-model="form.planGarageNum" placeholder="请输入规划总车库数-带产权" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="车库单价列举" :rules="[{ required: true, message: '请列举车库单价，**元/月，多个价格以“|”符号分隔', trigger: 'blur' }]" prop="garagePrice">
        <v-input v-model="form.garagePrice" placeholder="请列举车库单价，**元/月，多个价格以“|”符号分隔" :width="lwidth"></v-input>
      </el-form-item>
      <el-form-item label="规划非机动车位总数" :rules="[{ required: true, message: '请输入规划非机动车位总数', trigger: 'blur' }]" prop="planNotVehicleParkNum">
        <v-input-number v-model="form.planNotVehicleParkNum" placeholder="请输入规划非机动车位总数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划地上非机动车位总数" :rules="[{ required: true, message: '请输入规划地上非机动车位总数', trigger: 'blur' }]" prop="planUpperNotVehicleNum">
        <v-input-number v-model="form.planUpperNotVehicleNum" placeholder="请输入规划地上非机动车位总数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
      <el-form-item label="规划地下非机动车位总数" :rules="[{ required: true, message: '请输入规划地下非机动车位总数', trigger: 'blur' }]" prop="planDownNotVehicleNum">
        <v-input-number v-model="form.planDownNotVehicleNum" placeholder="请输入规划地下非机动车位总数" :max="99999" :width="width"></v-input-number>
      </el-form-item>
    </form-panel>
  </div>
</template>
<script>
import {
  addCommunityParkInfoURL
} from './api'

export default {
  props: {
    publicParams: Object,
    responseData: Object
  },
  data () {
    return {
      width: 220,
      lwidth: 450,
      submitUrl: addCommunityParkInfoURL,
      form: {
        parkId: '',
        communityId: '',    
        planFixedParkNum: undefined, //规划总固定车位数
        planUpperFixedParkNum: undefined,   //规划地上总固定车位数
        planDownFixedParkNum: undefined, //规划地下总固定车位数      
        sonAndMotherParkNum: undefined, //子母车位数
        fixedParkPrice: '', //固定车位单价列举
        planTemporaryParkNum: undefined, //规划总临停车位数
        temporaryParkPrice: '', //规划临停车位数单价列举
        planParkNum: undefined, //规划总车位数
        parkRate: '', //车位比
        planPropertyParkNum: undefined, //规划产权车位数
        planNotPropertyParkNum: undefined, //规划非产权车位数
        planMechanicsParkNum: undefined, //规划机械车位数
        planCivilAirDefenseParkNum: undefined, //规划人防车位数
        planUpperVehicleParkNum: undefined, //规划地上机动车位总数
        planDownVehicleParkNum: undefined, //规划地下机动车位总数
        parkPrice: '', //车位单价列举
        planGarageNum: undefined, //规划总车库数-带产权
        garagePrice: '', //车库单价列举
        planNotVehicleParkNum: undefined, //规划地上非机动车位总数
        planUpperNotVehicleNum: undefined, //规划地上非机动车位总数
        planDownNotVehicleNum: undefined, //规划地下非机动车位总数
      },
      submitConfig: {
        submitMethod: 'post'
      }
    }
  },
  mounted () {
    this.responseData && this.responseData.data && this.setBaseInfo()
  },
  methods: {
    setBaseInfo () {
      let { communityParkV } = this.responseData.data
      let keyArr = Object.keys(this.form)
      let newObj = {}
      keyArr.forEach(value => {
        newObj[value] = communityParkV[value]
      })
      this.form = newObj
    },
    submitBefore (data) {
      console.log(data,'data')
      return true
    },
    submitSuccess (data) {
      if (this.form.parkId) {
        this.$message.success('修改成功')
        this.$emit('contact', {
          method: 'setActiveLabel',
          data: '设备信息'
        })     
        return false
      } else {
        this.$message.success('保存成功')
        this.$emit('contact', {
            method: 'setActiveLabel',
            data: '设备信息'
          })
        return false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.baseInfoForm-container {
  height: 100%;
}
::v-deep .el-form-item__label{
  width: 192px !important;
}
::v-deep .el-form-item__content{
  margin-left: 192px !important;
}
</style>
